import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, FormControl, Select, MenuItem, InputLabel, Button, Box, Grid, TextField, Tabs, Tab, Dialog, DialogContent, ImageList, ImageListItem } from '@mui/material';
import axios from 'axios';
import { useAuth } from "../../../Router/AuthProvider.js";
import { getCall, postCall } from "../../../Api/axios";
import RaiseIssue from './RaiseIssue';

const Issues = () => {
    const issue = {order_id: { type: String },
        issue_id: { type: String },
        item_id: { type: String },
        status: { type: String },
        description_code: { type: String },
        description_name: { type: String },
        decsription_short_desc: { type: String },
        vendor_request: { type: String },
        customer_images: [String],
        customer_media: [String],
        customer_resolution_id: { type: String },
        resolution: [{
            id: { type: String },
            resolution_code: { type: String },
            resolution_desc: { type: String },
            refund_amount: { type: String },
        }]}

    const [issues, setIssues] = useState([]);
    const [refundAmount, setRefundAmount] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const auth = useAuth();
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        const org_id = auth?.user?.organization;
        console.log("org_id", org_id);
        fetchIssues(org_id)
    }, []);


    const fetchIssues = async (org_id) => {
        try {
            console.log("REACT_APP_IGM_CIENT_ENDPOINT", process.env.REACT_APP_IGM_CIENT_ENDPOINT);
            const url = `${process.env.REACT_APP_IGM_CIENT_ENDPOINT}/issues/${org_id}`;
            console.log("url", url);
            const response = await getCall(url);
            console.log("response", response);
            setIssues(response);
        } catch (error) {
            console.error('Error fetching issues:', error);
        }
    };

    const postPayload = async (payload) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_IGM_CIENT_ENDPOINT}/clientRequest`, payload);
            console.log('Payload posted successfully:', response.data);
        } catch (error) {
            console.error('Error posting payload:', error);
        }
    };

    const infoRequestedChange = (event, issueId) => {
        const issue = issues.find(issue => issue.issue_id === issueId);
        let description_code = '';
        let description_name = '';
        let description_short_desc = '';
        if (event.target.value === 'PHOTOS') {
            description_code = 'INFO_REQUESTED';
            description_name = "INFO001";
            description_short_desc = 'Provide more photos of the item';
        } else if (event.target.value === 'INVOICE') {
            description_code = 'INFO_REQUESTED';
            description_name = "INFO002";
            description_short_desc = 'Provide invoice for the item';
        }
        const payload = {
            issue_id: issue.issue_id,
            item_id: issue.item_id,
            status: issue.status,
            description_code: description_code,
            description_name: description_name,
            description_short_desc: description_short_desc,
        };
        postPayload(payload);
    };

    const resolutionProposedChange = (event, issueId) => {
        const issue = issues.find(issue => issue.issue_id === issueId);
        let resolution_code = '';
        let resolution_desc = '';
        let resolution_id = '';
        let refund_amount = '';
        if (event.target.value === 'REFUND OR REPLACE') {
            resolution_id = "1";
            resolution_code = 'RESOLUTION_PROPOSED';
            resolution_desc = 'Refund or replace the item';
            refund_amount = fetchRefundAmount(issue.order_id, issue.item_id);
        } else if (event.target.value === 'REFUND ONLY') {
            resolution_id = "2";
            resolution_code = 'RESOLUTION_PROPOSED';
            resolution_desc = 'Refund the item';
            refund_amount = fetchRefundAmount(issue.order_id, issue.item_id);
        }
        const payload = {
            issue_id: issue.issue_id,
            item_id: issue.item_id,
            status: issue.status,
            description_code: 'RESOLUTION_PROPOSED',
            description_name: 'RES001',
            description_short_desc: 'Resolution proposed',
            resolution: {
                resolution_id: resolution_id,
                resolution_code: event.target.value,
                resolution_desc: resolution_desc,
                refund_amount: refundAmount || refund_amount,
            },
        };
        postPayload(payload);

    };

    const fetchRefundAmount = (orderId, itemId) => {
        // Fetch refund amount from the mongodb database
        if (refundAmount !== null) {
            return refundAmount;
        } else {

        return '100';
        }
    };

    const handleClickOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage('');
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" className="issues-list">
            <Tabs value={tabValue} onChange={handleTabChange} style={{ marginBottom: '20px' }}>
                <Tab label="Seller Issues" />
                <Tab label="Customer Issues" />
            </Tabs>
            {tabValue === 0 && <RaiseIssue />}
            {tabValue === 1 && (
                <Card variant="outlined" style={{ width: '90%', marginTop: '20px' }}>
                    <CardContent>
                        <Typography variant="h4" component="h2" gutterBottom>
                            Issues
                        </Typography>
                        {issues.map(issue => (
                            <Card key={issue.issue_id} className="issue-card" variant="outlined" style={{ width: '90%', marginBottom: '20px' }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Typography variant="h5" component="div">
                                                {issue.issue_id.slice(-6)}
                                            </Typography>
                                            <Typography color="text.secondary">
                                                Status: {issue.status}
                                            </Typography>
                                            {issue.customer_images && issue.customer_images.length > 0 && (
                                                <ImageList cols={2} rowHeight={160}>
                                                    {issue.customer_images.map((image, index) => (
                                                        <ImageListItem key={index} onClick={() => handleClickOpen(image)}>
                                                            <img
                                                                src={image}
                                                                alt={`Preview of ${issue.issue_id} ${index + 1}`}
                                                                style={{ width: '100%', marginTop: '10px', cursor: 'pointer' }}
                                                                onError={(e) => { e.target.onerror = null; e.target.src = 'path/to/placeholder.jpg'; }}
                                                            />
                                                        </ImageListItem>
                                                    ))}
                                                </ImageList>
                                            )}
                                            {issue.customer_media && issue.customer_media.length > 0 && (
                                                <a href={issue.customer_media} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginTop: '10px' }}>
                                                    View PDF
                                                </a>
                                            )}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="h6" component="div" gutterBottom>
                                                Info Requested
                                            </Typography>
                                            <FormControl fullWidth>
                                                <InputLabel>Select an option</InputLabel>
                                                <Select
                                                    value={"INFO_REQUESTED"}
                                                    onChange={(e) => infoRequestedChange(e, issue.issue_id)}
                                                >
                                                    <MenuItem value="">--Select--</MenuItem>
                                                    <MenuItem value="PHOTOS">Provide more photos</MenuItem>
                                                    <MenuItem value="INVOICE">Provide invoice</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Typography variant="h6" component="div" gutterBottom style={{ marginTop: '20px' }}>
                                                Resolution Proposed
                                            </Typography>
                                            <FormControl fullWidth>
                                                <InputLabel>Select an option</InputLabel>
                                                <Select
                                                    value={"RESOLUTION_PROPOSED"}
                                                    onChange={(e) => resolutionProposedChange(e, issue.issue_id)}
                                                >
                                                    <MenuItem value="">--Select--</MenuItem>
                                                    <MenuItem value="REFUND OR REPLACE">REFUND OR REPLACE</MenuItem>
                                                    <MenuItem value="REFUND ONLY">REFUND ONLY</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                label="Refund Amount"
                                                value={refundAmount}
                                                onChange={(e) => setRefundAmount(e.target.value)}
                                                fullWidth
                                                style={{ marginTop: '20px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </CardContent>
                </Card>
            )}
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogContent>
                    <img src={selectedImage} alt="Full size" style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Issues;
