import React, { useEffect, useState } from "react";
import { Button, Modal } from "@mui/material";
import RenderInput from "../../../utils/RenderInput";
import useForm from "../../../hooks/useForm";
import { categoryFields } from "./product-fields";
import { getCall } from "../../../Api/axios";

import { PRODUCT_CATEGORY } from "../../../utils/constants";
import { PRODUCT_SUBCATEGORY } from "../../../utils/constants";

const containerClasses = "flex items-center";
const inputClasses =
  "w-80 h-full px-2.5 py-3.5 text-[#606161] bg-transparent !border-black flex";
const labelClasses =
  "w-40 my-4 text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block";

const getOrgDetails = async (org_id) => {
  const url = `/api/v1/organizations/${org_id}/storeDetails`;
  const res = await getCall(url);
  return res;
};

const getUser = async (id) => {
  const url = `/api/v1/users/${id}`;
  const res = await getCall(url);
  return res[0];
};

export const customizationFields = [
  {
    id: "productName",
    title: "Name",
    placeholder: "Enter Customization Name",
    type: "input",
  },
  //   {
  //     id: "default",
  //     title: "Default?",
  //     //    placeholder: "Enter Customization Name",
  //     type: "radio",
  //     options: [
  //       { key: "Yes", value: "Yes" },
  //       { key: "No", value: "No" },
  //     ],
  //     required: true,
  //   },
  {
    id: "MRP",
    title: "Price",
    placeholder: "Enter Customization Price",
    type: "number",
  },
  {
    id: "productCategory",
    title: "Product Category",
    placeholder: "Please Select Product Category",
    options: Object.entries(PRODUCT_CATEGORY).map(([key, value]) => {
      return { key: value, value: key };
    }),
    type: "select",
    disableClearable: true,
    required: true,
    isDisabled: true,
  },
  {
    id: "productSubcategory1",
    title: "Product SubCategory",
    placeholder: "Please Select Product SubCategory",
    options: [],
    type: "select",
    disableClearable: true,
    required: true,
  },
  {
    id: "UOM",
    title: "UOM",
    placeholder: "Select Unit Of Measurement",
    options: [
      { key: "unit", value: "unit" },
      { key: "dozen", value: "dozen" },
      { key: "gram", value: "gram" },
      { key: "kilogram", value: "kilogram" },
      { key: "tonne", value: "tonne" },
      { key: "litre", value: "litre" },
      { key: "millilitre", value: "millilitre" },
    ],
    type: "select",
    inputStyles: {
      width: 320,
    },
    disableClearable: true,
  },
  {
    id: "UOMValue",
    title: "UOMValue",
    placeholder: "Enter UOM Value",
    type: "input",
  },

  {
    id: "quantity",
    title: "Available Quantity",
    placeholder: "Enter Available Quantity",
    type: "number",
  },
  {
    id: "maxAllowedQty",
    title: "Maximum Quantity",
    placeholder: "Enter Maximum Quantity",
    type: "number",
  },
  {
    id: "vegNonVeg",
    title: "Veg/Non-Veg/Egg",
    placeholder: "Select Food Category",
    type: "select",
    options: [
      { key: "Veg", value: "VEG" },
      { key: "Non Veg", value: "NONVEG" },
      { key: "Egg", value: "EGG" },
    ],
    disableClearable: true,
    inputStyles: {
      width: 320,
    },
  },
  //   {
  //     id: "parent",
  //     title: "Parent",
  //     placeholder: "Enter Customization Name",
  //     type: "input",
  //     isDisabled: true,
  //   },
];

const AddCustomization = (props) => {
  const {
    category,
    showModal,
    handleCloseModal,
    newCustomizationData,
    setNewCustomizationData,
    handleAddCustomization,
  } = props;
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState(customizationFields);

  const categoryInitialValues = {
    productCategory: "",
    productSubcategory1: "",
  };

  const categoryForm = useForm(categoryInitialValues);

  useEffect(() => {
    if (newCustomizationData.default === "Yes") {
      setNewCustomizationData((prevState) => {
        return { ...prevState, MRP: "0" };
      });
    }
  }, [newCustomizationData.default]);

  // Add sub-category

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id).then((u) => {
      getOrgDetails(u.organization).then((org) => {
        let category = org?.storeDetails?.category;
        if (!category) navigate(`/application/store-details/${u.organization}`);
        categoryForm.setFormValues((prev) => {
          return { ...prev, productCategory: category };
        });
        let data = [...customizationFields]; // Create a copy of the fields array
        const subCategoryIndex = data.findIndex(
          (item) => item.id === "productSubcategory1"
        );
        data[subCategoryIndex].options = PRODUCT_SUBCATEGORY[category];
        setFields(data);
      });
    });
  }, []);

  useEffect(() => {
    let category = categoryForm.formValues["productCategory"];
    let sub_category = categoryForm.formValues["productSubcategory1"];
    console.log("category:", category);
    console.log("sub_category:", sub_category);
    setNewCustomizationData({
      ...newCustomizationData,
      productCategory: category,
      productSubcategory1: sub_category,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryForm.formValues.productSubcategory1]);

  const getCustomizationFields = (category_id) => {
    console.log("categoryid:", category_id);
    return customizationFields.find((field) => field.id === category_id);
  };
  const renderCategoryFields = () => {
    return categoryFields.map((category_id) => {
      let item = getCustomizationFields(category_id);
      return (
        item && (
          <RenderInput
            key={category_id}
            item={{
              ...item,
              error: categoryForm.errors?.[item?.id] ? true : false,
              helperText: categoryForm.errors?.[item.id] || "",
            }}
            state={categoryForm.formValues}
            stateHandler={categoryForm.setFormValues}
          />
        )
      );
    });
  };

  const validate = () => {
    const formErrors = {};
    formErrors.productName =
      newCustomizationData?.productName?.trim() == undefined ||
      newCustomizationData?.productName?.trim() === ""
        ? "Name is not allowed to be empty"
        : "";

    formErrors.MRP =
      newCustomizationData?.MRP < 0 ? `Please enter a valid price` : "";

    formErrors.UOM =
      newCustomizationData?.UOM == undefined || newCustomizationData?.UOM === ""
        ? "UOM is not allowed to be empty"
        : "";

    formErrors.UOMValue =
      newCustomizationData?.UOMValue == undefined ||
      newCustomizationData?.UOMValue === ""
        ? "UOM Value is not allowed to be empty"
        : newCustomizationData.UOMValue <= 0
        ? "Please Enter a Valid Value"
        : "";

    formErrors.quantity =
      newCustomizationData?.quantity == undefined ||
      newCustomizationData?.quantity === ""
        ? "Available Quantity is not allowed to be empty"
        : newCustomizationData.quantity <= 0
        ? "Please Enter a Valid Value"
        : "";

    formErrors.maxAllowedQty =
      newCustomizationData?.maxAllowedQty == undefined ||
      newCustomizationData?.maxAllowedQty === ""
        ? "Maximum Quantity is not allowed to be empty"
        : newCustomizationData.maxAllowedQty <= 0
        ? "Please Enter a Valid Value"
        : "";

    setErrors(formErrors);

    return !Object.values(formErrors).some((val) => val !== "");
  };

  const handleAdd = (e) => {
    e.stopPropagation();
    if (validate()) {
      setErrors({});
      handleAddCustomization();
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        open={showModal}
        onClose={() => {
          setErrors({});
          setNewCustomizationData({ MRP: 0 });
          handleCloseModal();
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "24px 40px",
            borderRadius: 20,
          }}
        >
          <p className="font-semibold text-xl" style={{ marginBottom: 10 }}>
            {props.mode === "edit"
              ? "Edit Customization"
              : "Add New Customization"}
          </p>
          <div className="w-auto">{renderCategoryFields()}</div>
          <div className="w-auto">
            {customizationFields.map((field) => {
              let disable = false;

              const fieldsToRender = [
                "productName",
                "default",
                "MRP",
                "UOM",
                "UOMValue",
                "quantity",
                "maxAllowedQty",
                "vegNonVeg",
                "parent",
              ];
              const fieldCategoryMap = {
                vegNonVeg: ["F&B"],
              };

              const shouldRenderField =
                fieldsToRender.includes(field.id) &&
                (!fieldCategoryMap[field.id] ||
                  fieldCategoryMap[field.id].includes(category));

              if (
                field.id === "default" &&
                newCustomizationData.defaultCustomizationId &&
                newCustomizationData.defaultCustomizationId !==
                  newCustomizationData.id
              ) {
                // disable default radio button if some other customization is already default
                disable = true;
              }

              if (
                field.id === "MRP" &&
                newCustomizationData.default === "Yes"
              ) {
                // disable price if the customization is default
                disable = true;
              }

              if (shouldRenderField) {
                return (
                  <RenderInput
                    item={{
                      ...field,
                      error: errors?.[field?.id] ? true : false,
                      helperText: errors?.[field.id] || "",
                    }}
                    state={newCustomizationData}
                    stateHandler={setNewCustomizationData}
                    key={field?.id}
                    containerClasses={containerClasses}
                    labelClasses={labelClasses}
                    inputClasses={inputClasses}
                    inputStyles={field?.inputStyles}
                    isDisabled={disable}
                  />
                );
              }

              return null;
            })}
          </div>
          <div className="flex justify-end mt-4">
            <Button variant="outlined" color="primary" onClick={handleAdd}>
              {props.mode === "edit" ? "Edit" : "Add"}
            </Button>
            <Button
              sx={{ marginLeft: 2 }}
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setErrors({});
                setNewCustomizationData({ MRP: 0 });
                handleCloseModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddCustomization;
