import OndcRoutes from "./Router/Router";
import "./Api/firebase-init";

function App() {
  return (
    <div className="App">
      <OndcRoutes />
    </div>
  );
}

export default App;
