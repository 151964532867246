import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, TextField, Card, CardContent, Grid } from '@mui/material';
import axios from 'axios';

const RaiseIssue = () => {
    const [selectedPolicies, setSelectedPolicies] = useState([]);
    const [description, setDescription] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState('');
    const [issues, setIssues] = useState([
        { id: 1, title: 'Issue 1', description: 'Description of issue 1', status: 'Pending' },
        { id: 2, title: 'Issue 2', description: 'Description of issue 2', status: 'Resolved' },
        // ...other issues
    ]);

    const handlePolicyChange = (event) => {
        setSelectedPolicies(event.target.value);
    };  

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async () => {
        const payload = {
            policies: selectedPolicies,
            description: description,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_IGM_CIENT_ENDPOINT}/raiseIssue`, payload);
            console.log('Issue raised successfully:', response.data);
        } catch (error) {
            console.error('Error raising issue:', error);
        }
    };

    const handleOptionsChange = (event) => {
        setSelectedOptions(event.target.value);
    };

    const handleOptionsText = (event) => {
        setOptions(event.target.value);
    };

    const handleUpdateSubmit = async () => {
        const payload = {
            options: selectedOptions,
            description: options,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_IGM_CIENT_ENDPOINT}/raiseIssue`, payload);
            console.log('Issue raised successfully:', response.data);
        } catch (error) {
            console.error('Error raising issue:', error);
        }
    };

    const handleUpdateIssue = (id, description) => {
        setIssues(issues.map(issue => 
            issue.id === id ? { ...issue, description } : issue
        ));
    };

    const UpdateIssueBox = ({ issue, onUpdate }) => {
        const [description, setDescription] = useState(issue.description);
        const [selectedOptions, setSelectedOptions] = useState([]);

        const handleOptionsChange = (event) => {
            setSelectedOptions(event.target.value);
        };

        const handleUpdate = async () => {
            const payload = {
                options: selectedOptions,
                description: description,
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_IGM_CIENT_ENDPOINT}/updateIssue`, payload);
                console.log('Issue updated successfully:', response.data);
                onUpdate(issue.id, description);
            } catch (error) {
                console.error('Error updating issue:', error);
            }
        };

        return (
            <Card style={{ marginBottom: '20px', width: '90%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" component="h3" gutterBottom>
                                Status: {issue.status}
                            </Typography>
                            <h3>{issue.title}</h3>
                            <p>{issue.description}</p>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box display="flex" flexDirection="column" alignItems="center" className="update-issue-form">
                                <FormControl fullWidth style={{ marginBottom: '20px' }}>
                                    <InputLabel>Other Options</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedOptions}
                                        onChange={handleOptionsChange}
                                    >
                                        <MenuItem value="ESCALATED">Escalated</MenuItem>
                                        <MenuItem value="RESOLVED">Resolved</MenuItem>
                                        <MenuItem value="PENDING">Pending</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    style={{ marginBottom: '20px' }}
                                />
                                <Button variant="contained" color="primary" onClick={handleUpdate}>
                                    Update
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    };

    return (
        <>
        <Box display="flex" flexDirection="column" alignItems="center" className="raise-issue-form" style={{ margin: '0 auto', maxWidth: '800px' }}>
            <Typography variant="h4" component="h2" gutterBottom>
                Raise a Policy Issue
            </Typography>
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Select Policies</InputLabel>
                <Select
                    multiple
                    value={selectedPolicies}
                    onChange={handlePolicyChange}
                >
                    <MenuItem value="VIS001">Seller not visible on the interface</MenuItem>
                    <MenuItem value="VIS002">Product not visible</MenuItem>
                    <MenuItem value="VIS003">Incorrect description</MenuItem>
                </Select>
            </FormControl>
            <TextField
                label="Description"
                value={description}
                onChange={handleDescriptionChange}
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: '20px' }}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </Box>
        <Card style={{ marginTop: '50px', width: '90%', margin: '0 auto' }}>
            <CardContent>
                {issues.map(issue => (
                    <div key={issue.id} style={{ width: '100%' }}>
                        <UpdateIssueBox issue={issue} onUpdate={handleUpdateIssue} />
                    </div>
                ))}
            </CardContent>
        </Card>
        </>
    );
};

export default RaiseIssue;
